<template>
  <div class="config-table">
    <b-table :items="configs" :fields="fields" responsive="sm" small>
      <template #cell(question)="data">
        <div>
          <b-row v-if="configId == data.index">
            <b-form-input v-model="data.item.start" class="col-5 mr-1" placeholder="start" />
            <b-form-input v-model="data.item.end" class="col-5" placeholder="end" />
          </b-row>
          <p v-else>{{ getQuestion(data.item) }}</p>
        </div>
      </template>

      <template #cell(cap_time)="data">
        <b-form-input v-if="configId == data.index" v-model="data.item.cap_time" placeholder="5 (s)" />
        <p v-else>{{ data.item.cap_time }}</p>
      </template>

      <template #cell(score)="data">
        <b-form-input v-if="configId == data.index" v-model="data.item.point" placeholder="score" />
        <p v-else>{{ data.item.point }}</p>
      </template>

      <template #cell(action)="data">
        <div class="d-flex flex-end">
          <b-button
            @click="editOrUpdateConfig(data.index)"
            size="sm"
            style="margin-right: 5px"
            variant="primary"
          >{{ configId == data.index ? "Save" : "Edit" }}</b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="confirmDelete(data.index)"
          >{{ configId == data.index ? "Cancel" : "Delete" }}</b-button>
        </div>
      </template>
    </b-table>
    <div class="add-config">
      <b-button class="text-center" variant="primary" @click="addConfig">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add config</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import service from "../../service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },

  props: {
    config: { type: Object, default: () => { } },
  },

  data() {
    return {
      fields: [
        "question",
        "cap_time",
        "score",
        { key: "action", label: "" },
      ],
      configId: null,
      configs: []
    };
  },

  watch: {
    config(value) {
      this.configs = JSON.parse(JSON.stringify(value.value))
    }
  },

  methods: {
    getQuestion(config) {
      if (!config.end || !config.start) {
        return config.start ? '>=' + config.start : config.end + '<='
      }

      return config.start + '-' + config.end
    },

    async editOrUpdateConfig(index) {
      if (!(this.configId + '') || this.configId != index) {
        this.configId = index;
      } else {
        const response = await this.updateConfigs();
        if (response.data.type == "SUCCESS") {
          this.resetData();
          this.$emit("getConfig");
        }
      }
    },

    async updateConfigs() {
      const dataUpdate = {
        ...this.config,
        value: this.configs,
      }

      return await service.update(dataUpdate);
    },

    async cancelOrDelete(id) {
      if (this.configId == id) {
        this.resetData();
      } else {
        this.configs = [...this.configs.splice(0, id), ...this.configs.slice(1)]
        await this.updateConfigs()
        this.$emit("getConfig");
      }
    },

    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.cancelOrDelete(id);
          }
        });
    },

    resetData() {
      this.configId = null;
      this.configs = JSON.parse(JSON.stringify(this.config.value))
    },

    addConfig() {
      this.configs.push({})
      this.configId = this.configs.length - 1
    }
  },
};
</script>
<style lang="scss">
.config-table {
  table {
    td {
      padding-top: 0.72rem !important;
      padding-bottom: 0.72rem !important;
      p {
        margin: unset;
      }
      &:first-child {
        width: 300px;
      }
    }
    .flex-end {
      justify-content: flex-end
    }
  }
  .add-config {
    width: 100%;
    button {
      margin: auto;
      display: block;
    }
  }
}
</style>
