module.exports = {
    GOGA_RANK_GAME_PLAY_CONFIG_CONFIG: {
        key: 'GOGA_RANK_GAME_PLAY_CONFIG',
        default_data: [
            {
                start: 1,
                end: 5,
                cap_time: 20,
                point: 200
            },
            {
                start: 6,
                end: 10,
                cap_time: 17,
                point: 230
            },
            {
                start: 11,
                end: 15,
                cap_time: 15,
                point: 250
            },
            {
                start: 16,
                end: 20,
                cap_time: 13,
                point: 270
            },
            {
                start: 21,
                end: 25,
                cap_time: 10,
                point: 300
            },
            {
                start: 26,
                end: 30,
                cap_time: 8,
                point: 320
            },
            {
                start: 31,
                end: 35,
                cap_time: 6,
                point: 340
            },
            {
                start: 36,
                end: 40,
                cap_time: 4,
                point: 360
            },
            {
                start: 41,
                end: 45,
                cap_time: 3,
                point: 370
            },
            {
                start: 46,
                end: 50,
                cap_time: 2,
                point: 380
            },
            {
                start: 51,
                end: null,
                cap_time: 1,
                point: 390
            }
        ]
    },

    MODULE_CATEGORY: 'goga_rank',
}
